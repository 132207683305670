import { ProntoSettingService } from './../../../services/utils/pronto-setting.service';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, HostListener, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { ConfigManagerService } from '../../../Managers/config-manager.service';
import { CreateVideoManagerService } from '../../../Managers/create-video-manager.service';
import { aspectRatio, template, templatesCategory } from '../../../../app/models/template';
import { cDataService } from '../../../services/data.service';
import { cEditVideoService } from '../../../services/edit-video.service';
import { cSessionService } from '../../../services/session.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { mediaFile } from './../../../models/media';
import { MediaManagerService } from './../../../Managers/media-manager.service';
import { ErrorService } from 'src/app/services/error.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadFileDialogComponent } from 'src/app/shared/components/upload-file-dialog/upload-file-dialog.component';
import { takeUntil, filter } from 'rxjs/operators';
import { BrandService } from '../../myAccount/manage-brand/service/BrandService';
import { brand } from 'src/app/models/admin';
import { VideoManagerService } from 'src/app/Managers/video-manager.service';
import { LocationStrategy } from '@angular/common';
import { cClientService } from 'src/app/services/client.service';
import { VideoDataStore } from 'src/app/stores/video.store';
import { duration } from 'moment';
import { NzTableInnerScrollComponent } from 'ng-zorro-antd/table';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-looknfeel',
    templateUrl: './looknfeel.component.html',
    styleUrls: ['./looknfeel.component.scss'],
})
export class LooknFeelComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    @ViewChild('previewTemplate') previewTemplate: ElementRef;
    @ViewChild('closeModal') closeModal: ElementRef;
    @ViewChild('closeSecondModal') closeSecondModal: ElementRef;
    bDisplayAddInputField: boolean = false;
    fLooknFeelForm: FormGroup;
    lTemplateCategory: any = [];
    sSessionID: string = '';
    sUserEmail: string = '';
    sVideoId;
    oSavedFormData: any;
    inputValue: string = '';
    lKey_terms: any = [];
    lKeyTermsTags: any = [];
    oPrimaryColor: any;
    oFontColor: any;
    sDisplayResponseErrorMessage: string;
    bDisplayErrorMessage: boolean = false;
    lSelectedTemplateCategory: any = [];
    sSelectedTemplateThumbnailURL: string;
    sSelectedTemplatePreviewVideoURL: string;
    // sTemplateChange : string;
    oUploadedLogo: any;
    nUploadedLogoSize: number = 0;
    sUploadedLogoName: string = 'No file choosen.';
    bCreateVideoMode: boolean = true;
    bDisplayUploadLogoSection: boolean = true;
    bDisplayBrandUploadLogoSection: boolean = true;
    oLogoDetails: any;
    sDisableEditModeToggle: string = 'false';
    bSendRequestInBackground: boolean = true;
    showLogoUploadModal = false;
    brand_id: string;
    logo_type = 'own';

    @ViewChild('AddInputTagElement') AddInputTagElement: ElementRef;
    oTemplateConfigurations: any = [];
    bSecondLogo: any;
    nUploadedSecondLogoSize: any;
    oUploadedSecondLogo: any;
    brandUploadedSecondLogo: any;
    sUploadedSecondLogoName: any;
    showBrandLogoSelectionPopup: boolean = false;
    videoThemeColor = undefined;
    videoThemeColorPicked = undefined;
    videoFontColors = [{ id: 1, color: undefined }];
    defaultColor = '#333333';
    videoColorLoaded = false;
    defaultVideoColor = '';
    sub: Subscription;
    sub2: Subscription;
    public templateCategories: any[] = [];
    countForlouderBrand = 0;
    formListStart = 0;
    constructor(
        private mediaManger: MediaManagerService,
        private oRoutes: Router,
        private oDataService: cDataService,
        private oSessionService: cSessionService,
        public videoManger: CreateVideoManagerService,
        public configManager: ConfigManagerService,
        private oEditVideoService: cEditVideoService,
        public onBoardingManager: OnboardingManagerService,
        private errorService: ErrorService,
        private dialog: MatDialog,
        private cd: ChangeDetectorRef,
        private brandService: BrandService,
        private videoService: VideoManagerService,
        private location: LocationStrategy,
        public prontoSettingService: ProntoSettingService,
        private ngZone: NgZone,
        public clientService: cClientService,
        private videoStore: VideoDataStore,
    ) {
        // history.pushState(null, null, window.location.href);
        // // check if back or forward button is pressed.
        // this.location.onPopState(() => {
        //     console.log('Called and siabled ..');
        //     history.pushState(null, null, window.location.href);
        //     this.prontoSettingService.emitBackButtonDialogTextSubject({
        //         visible: true,
        //         text: 'Your work hasn`t been saved yet. If you leave now, any unsaved progress will be lost.',
        //     });
        // });
    }

    async ngOnInit() {
        if( this.onBoardingManager.profile.organization_id == environment.LouderId){
            this.countForlouderBrand = 1;
        }
        if(this.videoManger.selectedVideo?.brand_id != undefined){
            this.formListStart = 1;
        }
        this.videoManger.videoBrand = undefined;
        this.videoManger.wizardCurrentTab = '';
        this.defaultVideoColor = this.videoManger.themeDefaultColor;
        this.sub2 = this.videoManger.previousSubmit.subscribe((emit) => {
            this.oRoutes.navigateByUrl('/wizard/overview');
        });
        this.sub = this.videoManger.submitForm.subscribe((emit) => {
            this.save(emit);
        });

        this.sSessionID = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        // console.log(this.videoManger.selectedVideo);
        if (this.videoManger.selectedVideo.template_category) {
            this.configManager.Templates.forEach((e) => {
                if (e.category == this.videoManger.selectedVideo.template_category) {
                    this.selectedCategory = e;
                }
            });
        } else {
            this.selectedCategory = this.configManager.Templates[0];
            // console.log(' >> template selected ',  this.configManager.Templates[0]);
            // console.log(' >> first selected ',  this.selectedCategory)
            this.videoManger.selectedVideo.template_category = this.configManager.Templates[0].category;
        }

        // console.log(" this.configManager.Templates ", this.configManager.Templates);

        // this.loadTemplatesSignedUrl();
        await this.selectCategory(this.selectedCategory);
        if (this.videoManger.selectedVideo.template_name) {
            this.templates.forEach((e) => {

                if (e.template_name == this.videoManger.selectedVideo.template_name) {
                    // console.log(this.videoManger.selectedVideo.template_name , " ::: Template Iterm ... ", e);
                    let selectedTemplateItem = e.previews.filter( (item : any) => {
                        return (item.duration ==  this.videoManger.selectedVideo.duration && item.aspect_ratio == this.videoManger.selectedVideo.aspect_ratio)
                    });
                    selectedTemplateItem.forEach((itemMatched : any) => {
                        this.videoManger.changeTemplate(itemMatched);
                        this.videoManger.changeTemplateForClip(itemMatched);
                        this.selectTemplate(itemMatched);
                        // console.log(" selected template should be this ", selectedTemplateItem);
                    this.resetAddressTag();
                    this.videoManger.selectedVideo.template_name = e.template_name;

                    });

                }
            });
        } else {
            this.selectedTemplate = this.configManager?.templateCategories[0].data;
            this.videoManger.changeTemplate(this.selectedTemplate);
            this.videoManger.changeTemplateForClip(this.selectedTemplate);
            this.resetAddressTag();
            this.videoManger.selectedVideo.template_name = this.selectedTemplate.template_name;
        }

        // console.log('Template cateogory /././/');
        if (this.videoManger?.selectedVideo?.template_category != '' && this.videoManger?.selectedVideo?.template_info) {
            // console.log("Navigate to time line preview ");
            this.oRoutes.navigate(['/wizard/timeline-view']);
        }
        this.lKey_terms = this.videoManger.selectedVideo?.key_terms;

        if (this.videoManger.selectedVideo.logo_img) {
            this.bDisplayUploadLogoSection = true;
            this.oUploadedLogo = {
                url: this.videoManger.selectedVideo.logo_img_url,
            };
        }

        if (this.videoManger.selectedVideo.second_logo_img) {
            this.oUploadedSecondLogo.url = {
                url: this.videoManger.selectedVideo.second_logo_img_url,
            };
        }
        if (!this.videoManger.selectedVideo?.logo_type) {
            this.videoManger.selectedVideo.logo_type = 'own';
        }
        this.oPrimaryColor = this.videoManger.selectedVideo.theme_colors ? this.videoManger.selectedVideo.theme_colors[0] : undefined;
        this.oFontColor = this.videoManger.selectedVideo.font_color;
        this.brand_id = this.videoManger.selectedVideo.brand_id;
        // await this.videoService
        //     .getVideoById(this.sUserEmail, this.sSessionID, this.videoManger.selectedVideo.fpath)
        //     .then(async (res: any) => {
        //         console.log('response video create ', res);
        //         if (res?.color) {
        //             this.defaultVideoColor = res?.color;
        //         }
        //     });
        if(this.videoManger?.selectedVideo.theme_colors){
            this.videoThemeColorPicked = this.videoManger?.selectedVideo.theme_colors;
        }
        if (this.videoManger?.selectedVideo?.brand_id) {
            this.brandService.getBrandProfile(this.videoManger?.selectedVideo?.brand_id).then((brand: brand) => {
                this.videoManger.videoBrand = brand;
                if (brand.primary_color) {
                    this.videoThemeColor = brand.primary_color;
                } else {
                    this.videoThemeColor = this.defaultVideoColor;
                }

                if (brand.secondary_colors && brand.secondary_colors.length > 0) {
                    this.videoFontColors = [];
                    brand.secondary_colors.map((color, index) => {
                        this.videoFontColors.push({
                            id: index,
                            color: color,
                        });
                    });
                }
                this.videoColorLoaded = true;
                this.CreateVideoLooknFeelComponent_InitializeJqueryCode();
            });
        } else {
            if (this.onBoardingManager.profile.logo_file && this.onBoardingManager.profile.logo_file != '') {
                // console.log(' LOAD USER LOGO FILE HERE ...');
                this.oLogoDetails = await this.oEditVideoService.EditVideoService_ReturnFileDetails(
                    this.onBoardingManager.profile.logo_file,
                );
            }
            // console.log('this.oLogoDetails ', this.oLogoDetails);

            if (this.onBoardingManager.profile.primary_color) {
                this.videoThemeColor = this.onBoardingManager.profile.primary_color;
            }
            if (this.onBoardingManager.profile.secondary_colors && this.onBoardingManager.profile.secondary_colors.length > 0) {
                this.videoFontColors = [];
                this.onBoardingManager.profile.secondary_colors.map((color, index) => {
                    this.videoFontColors.push({
                        id: index,
                        color: color,
                    });
                });
            }
            this.videoColorLoaded = true;
            this.CreateVideoLooknFeelComponent_InitializeJqueryCode();
        }
        window['clearSelectedColor'] = { component: this, zone: this.ngZone, loadAngularFunction: () => this.clearSelectedColor() };
    }

    public selectedCategory: templatesCategory;
    public selectedTemplate: template;
    public templates: template[] = [];

    loadTemplatesSignedUrl() {
        // console.log(' main template object >> ', this.videoManger.selectedVideo.duration, this.videoManger.selectedVideo.aspect_ratio);
        let fpathMedia = [];

        if (
            this.configManager.durationVideoMap.get('' + this.videoManger.selectedVideo.duration) &&
            this.configManager.durationVideoMap
                .get('' + this.videoManger.selectedVideo.duration)
                .get(this.videoManger.selectedVideo.aspect_ratio)
        ) {
            let templateData = this.configManager.durationVideoMap
                .get('' + this.videoManger.selectedVideo.duration)
                .get(this.videoManger.selectedVideo.aspect_ratio);

            templateData.forEach((element: any) => {
                fpathMedia.push(element.data.thumb);
                fpathMedia.push(element.data.preview);
                this.templateCategories.push(element);
            });
        }
        this.clientService.getThumbBatchSignedURL(this.sUserEmail, this.sSessionID, fpathMedia).then((bUrls: any) => {
            let output = bUrls['output'];
            this.templateCategories?.forEach((ele) => {
                ele.data.url = output.filter((a) => a[ele.data.preview])[0][ele.data.preview];
                ele.data.preview_url = output.filter((a) => a[ele.data.preview])[0][ele.data.preview];
                ele.data.thumbnail = output.filter((a) => a[ele.data.thumb])[0][ele.data.thumb];
            });

            // console.log('templates2----2', this.Templates);
        });
    }
    resetAddressTag() {
        // console.log(" this.selectedTemplate ", this.selectedTemplate);
        this.videoManger.showAddressTabOnCreateVideo = !(this.selectedTemplate?.address_fields == false);

        this.storeSelectedTemplate();
    }
    storeSelectedTemplate() {
        this.videoStore.selectedTemplate = this.selectedTemplate;
        // console.log("selected template ", this.selectedTemplate);
    }
    async selectCategory(category: templatesCategory) {
        return new Promise(async (resolve, reject) => {
            this.selectedCategory = category;
            this.templates = [];
            this.selectedCategory?.templates?.forEach((e: any) => {
                // console.log(e);
                e.previews.forEach((pr) => {
                    if (pr.duration === this.videoManger.selectedVideo.duration) {
                        // console.log(' selected ', pr);
                        e.preview_url = pr.url;
                        e.video_preview_url = pr.url;
                        e.thumbnail = pr.thumbnail;
                        this.templates.push(e);
                    }
                });
            });

            await this.configManager.loadTemplatesSignedUrl(
                this.videoManger.selectedVideo.duration,
                this.videoManger.selectedVideo.aspect_ratio,
                this.sUserEmail,
                this.sSessionID,
                category.category,
                this.selectedCategory,
            );

            // console.log(" this.videoManger.selectedVideo.duration ", this.videoManger.selectedVideo.duration);
            // console.log("Selected Template ", this.templates);
            this.selectedTemplate = this.templates[0];
            this.storeSelectedTemplate();
            this.videoManger.changeTemplate(this.selectedTemplate);
            this.videoManger.changeTemplateForClip(this.selectedTemplate);
            this.resetAddressTag();
            resolve(true);
        });
    }

    selectTemplate(template: template) {
        this.selectedTemplate = template;
        this.resetAddressTag();
        this.videoManger.changeTemplateForClip(template);
        this.videoManger.changeTemplate(template);

        this.bSecondLogo = this.selectedTemplate.second_logo;
    }

    save(message) {
        if (this.videoManger?.videoBrand?.primary_font && this.videoManger?.videoBrand?.primary_font != '') {
            this.videoManger.selectedVideo.font_file = this.videoManger?.videoBrand?.primary_font;
        }
        this.updateVideoLocally();
        this.videoManger.selectedVideo.step = 'looknfeel';
        this.videoManger
            .createVideo(this.videoManger.selectedVideo)
            .then((res) => {
                if (!this.videoManger.isEditMode) {
                    this.oRoutes.navigateByUrl('/wizard/content');
                } else {
                    this.oRoutes.navigateByUrl('/wizard/preview');
                }
            })
            .catch((err) => {});
        //,
    }
    private updateVideoLocally() {
        this.videoManger.selectedVideo.template_category = this.selectedCategory.category;
        this.videoManger.selectedVideo.key_terms = this.lKey_terms;
        this.videoManger.selectedVideo.template_name = this.selectedTemplate.template_name;

        this.videoManger.selectedVideo.logo_img = this.CreateVideoLooknFeelComponent_ReturnLogo();
        this.videoManger.selectedVideo.second_logo_img = this.CreateVideoLooknFeelComponent_ReturnSecondLogo();
        // console.log( " theme colorthis.videoThemeColor")
        if (this.videoThemeColor && this.videoThemeColorPicked != undefined) {
            // console.log('Saving colorsss');
            this.videoManger.selectedVideo.theme_colors = [this.videoThemeColor];
            if (!this.videoManger.selectedVideo.theme_colors) {
                // this.videoManger.selectedVideo.theme_colors = [this.videoManger.themeDefaultColor];
                this.videoManger.selectedVideo.theme_colors = null;
            }
        } else {
            this.videoManger.selectedVideo.theme_colors = [];
        }
        if (this.videoFontColors && this.videoFontColors.length > 0) {
            this.videoManger.selectedVideo.font_color = this.videoFontColors[0].color;
            if (!this.videoManger.selectedVideo.font_color) {
                // this.videoManger.selectedVideo.font_color = this.videoManger.fontDefaultColor;
                this.videoManger.selectedVideo.font_color = null;
            }
        }

        const colorSecondary = [];
        this.videoFontColors.map((item) => {
            if (item.color && item.color != '') {
                colorSecondary.push(item.color);
            }
        });
        // console.log(this.videoThemeColor);
        // console.log(colorSecondary);
        if (colorSecondary.length > 0) {
            this.videoManger.selectedVideo.secondary_colors = colorSecondary;
        }
        // console.log(this.videoManger.selectedVideo.secondary_colors);
    }

    NavigateToDashboard() {
        this.oSessionService.cSessionService_ResetHighlightedTabs();
        let videoCountCheck = this.oSessionService.cSessionService_GetUserVideosCount();
        if (videoCountCheck > 0) {
            this.oRoutes.navigateByUrl('/dashboard/myvideos');
        } else {
            this.oRoutes.navigateByUrl('/dashboard/first-time-user');
        }
    }
    CreateVideoLooknFeelComponent_UpdateVideo(bUpdateVideo: boolean, bIncommingSendRequestInBackground: boolean) {
        // console.log("Update video ==> ", bUpdateVideo);
        this.bSendRequestInBackground = bIncommingSendRequestInBackground;
        if (bUpdateVideo) {
            this.oSessionService.cSessionService_SetLooknFeelForm(this.fLooknFeelForm.value);
            const oEditVideoForm = this.oEditVideoService.EditVideoService_ReturnCombinedPayload();
            // console.log("Updated Video Request : ==> ", oEditVideoForm);
        }
    }
    PreviewTemplate() {
        this.previewTemplate?.nativeElement?.play();
    }
    ngOnDestroy() {
        if (this.sub2) this.sub2.unsubscribe();
        if (this.sub) this.sub.unsubscribe();

        if (this.videoManger.isEditMode) {
            this.updateVideoLocally();
        }
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    CreateVideoLooknFeelComponent_LoadSelectedTemplate(oIncommingTemplate) {
        this.sSelectedTemplateThumbnailURL = oIncommingTemplate.sThumbnail;
        this.sSelectedTemplatePreviewVideoURL = oIncommingTemplate.sURL;
    }

    CreateVideoLooknFeelComponent_FocusoutFromInput() {
        if (this.inputValue !== '') {
            if (!this.lKey_terms) this.lKey_terms = [];
            this.lKey_terms.push(this.inputValue);
        }
        setTimeout(() => {
            this.bDisplayAddInputField = false;
        }, 100);
    }
    AddTagInputField() {
        this.bDisplayAddInputField = false;
        if (!this.lKey_terms) this.lKey_terms = [];

        let tagIndex = this.lKey_terms.findIndex((item: string) => {
            return item.toLowerCase() == this.inputValue.trim().toLowerCase();
        });

        if (tagIndex == -1 && this.inputValue.trim() != '') {
            this.lKey_terms.push(this.inputValue.trim());
        }
        setTimeout(() => {
            this.bDisplayAddInputField = false;
        }, 100);

        this.inputValue = '';
    }
    addTagOnKeyEnter(keycode) {
        if ((keycode == 13 && this.inputValue != '') || (keycode == 188 && this.inputValue != '')) {
            this.AddTagInputField();
        }
    }
    CreateVideoLooknFeelComponent_RemoveTagListElements(index) {
        this.lKey_terms.splice(index, 1);
    }
    CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInput() {
        this.inputValue = '';
        setTimeout(() => this.AddInputTagElement.nativeElement.focus());
        this.bDisplayAddInputField = true;
    }
    CreateVideoLooknFeelComponent_GetInputValue(event) {
        this.inputValue = event.target.value;
        // console.log("Input Value ==>", this.inputValue);
    }

    async CreateVideoLooknFeelComponent_UploadSecondaryLogoFile(event) {
        let file = event.target.files[0];
        this.nUploadedSecondLogoSize = file.size;
        // console.log('CreateVideoLooknFeelComponent_UploadFile : File To Upload ==> ', file);

        let media = new mediaFile();
        media.file = file;
        media.name = file.name;

        this.mediaManger
            .uploadMediaDefaultLibrary(this.sUserEmail, this.sSessionID, media)
            .then((res) => {
                // console.log('CreateVideoLooknFeelComponent_UploadSecondaryLogoFile : Response ==>', res);
                this.oUploadedSecondLogo = res;
                this.sUploadedSecondLogoName = file.name;
                this.closeSecondModal.nativeElement.click();
            })
            .catch((error) => {
                // console.log(error);
                this.oUploadedLogo = null;
                this.sDisplayResponseErrorMessage = error.error.errorMessage;
                this.bDisplayErrorMessage = true;
                this.closeModal.nativeElement.click();
                setTimeout(() => {
                    this.bDisplayErrorMessage = false;
                }, 5000);
            });
    }
    //
    CreateVideoLooknFeelComponent_GetPrimaryColor(event) {
        const primaryColor = event.target.value;
        // console.log("Primary Color ==>", primaryColor);
        this.videoThemeColor = primaryColor;
    }

    setColorToProperty(event, type, index = undefined) {
        const fontColor = event.target?.defaultValue;

        if (!this.canSetColor) {
            if (type == 'theme') {
                this.canSetColor = true;
                this.videoManger.selectedVideo.theme_colors = undefined;
                this.videoThemeColor = undefined;
                this.videoThemeColorPicked = false;
            } else {
                this.videoFontColors[0].color = undefined;
                this.videoManger.selectedVideo.font_color = undefined;
            }
        } else {
            if (type == 'theme') {
                this.videoThemeColor = fontColor;
                this.videoManger.selectedVideo.theme_colors = [fontColor];
                this.videoThemeColorPicked = [fontColor];
            } else {
                if (this.videoFontColors.length == 0) this.videoFontColors = [{ id: 0, color: '' }];

                this.videoFontColors[0].color = fontColor;
                this.videoManger.selectedVideo.font_color = fontColor;
                // console.log('Font color is here ', this.videoManger.selectedVideo.font_color);
            }
        }
    }
    CreateVideoLooknFeelComponent_GetFontColor(event) {
        const fontColor = event.target.value;
        // console.log("Font Color ==>", fontColor);
        this.oFontColor = fontColor;
    }
    CreateVideoLooknFeelComponent_ReturnFileSize(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    CreateVideoLooknFeelComponent_ToggleView(event) {
        if (event.target.checked) {
            this.oRoutes.navigateByUrl('/wizard/overview');
        } else {
            this.oRoutes.navigateByUrl('edit-video/timeline-view');
        }
    }
    currentUploadedLogoSection = 'own';

    toggleVideoLogoUploadSection(type: string) {
        this.videoManger.selectedVideo.logo_type = type;
        this.currentUploadedLogoSection = type;
        if (type == 'brand' && this.brandUploadedSecondLogo && this.brandUploadedSecondLogo.fpath) {
            this.videoManger.selectedVideo.logo_img = this.brandUploadedSecondLogo.fpath;
            this.videoManger.selectedVideo.logo_img_url = this.brandUploadedSecondLogo.url;
        }
        if (type == 'profile' && this.brandUploadedSecondLogo && this.brandUploadedSecondLogo.fpath) {
            this.videoManger.selectedVideo.logo_img = this.brandUploadedSecondLogo.fpath;
            this.videoManger.selectedVideo.logo_img_url = this.brandUploadedSecondLogo.url;
        } else {
            if (this.oUploadedLogo && type == 'own') {
                this.videoManger.selectedVideo.logo_img = this.oUploadedLogo.visual_path;
                this.videoManger.selectedVideo.logo_img_url = this.brandUploadedSecondLogo.url;
            }
        }
    }
    async toggleLogoUploadSection(event: any, type = '') {
        let value = event.target.value;
        this.bDisplayUploadLogoSection = false;
        this.bDisplayBrandUploadLogoSection = false;
        if (value === 'upload-logo') {
            this.bDisplayUploadLogoSection = true;
        } else {
            if (type == 'brand') {
                this.bDisplayBrandUploadLogoSection = true;
            } else {
                if (type == 'profile') {
                    // TODO need to verify again it later
                    // if (this.onBoardingManager.profile.logo_file) {
                    //     this.oUploadedLogo = await this.oEditVideoService.EditVideoService_ReturnFileDetails(
                    //         this.onBoardingManager.profile.logo_file,
                    //     );
                    //     this.videoManger.selectedVideo.logo_img_url = this.oUploadedLogo?.url;
                    // } else {
                    //     this.bDisplayUploadLogoSection = false;
                    // }
                }
            }
        }
        this.fLooknFeelForm?.patchValue({
            bDisplayUploadLogoSection: this.bDisplayUploadLogoSection,
        });
    }
    CreateVideoLooknFeelComponent_ReturnLogo() {
        let logo_path;
        if (this.currentUploadedLogoSection == 'brand') {
            return this.videoManger.selectedVideo.logo_img;
        } else {
            if (this.bDisplayUploadLogoSection) {
                logo_path = this.oUploadedLogo ? this.oUploadedLogo.visual_path : '';
            } else {
                logo_path = this.oLogoDetails ? this.oLogoDetails.fpath : '';
            }
            return logo_path;
        }
    }
    CreateVideoLooknFeelComponent_ReturnSecondLogo() {
        let logo_path;
        if (this.bSecondLogo) {
            logo_path = this.oUploadedSecondLogo ? this.oUploadedSecondLogo.visual_path : '';
        }
        return logo_path;
    }
    CreateVideoLooknFeelComponent_InitializeJqueryCode() {
        let chatScript = document.createElement('script');
        chatScript.type = 'text/javascript';
        chatScript.text = `
    $(document).ready(function() {
      $(window).keydown(function(event){
        // if(event.keyCode == 13) {
        //   event.preventDefault();
        //   return false;
        // }
      });
    });
    Coloris({
      el: '.coloris',
      clearButton: {
        show: false,
        label: 'Clear'
      },
      // close button
      closeButton: true,
      closeLabel: 'Close',
    // clearButton: true,
    // clearLabel: 'Clear',
      swatches: [
        '#ffffff',
          '#264653',
          '#2a9d8f',
          '#e9c46a',
          '#f4a261',
          '#e76f51',
          '#d62828',
          '#023e8a',
          '#0077b6',
          '#0096c7',
          '#ffffff',
          '#00b4d8',
          '#000000',
      ]
  });`;
        document.body.appendChild(chatScript);
        const listBrandColors = [];
        if (this.videoManger?.videoBrand?.primary_color) {
            listBrandColors.push(this.videoManger.videoBrand.primary_color);
        }
        if (this.videoManger?.videoBrand?.secondary_colors) {
            this.videoManger.videoBrand.secondary_colors.map((color) => {
                if (listBrandColors.indexOf(color) === -1) {
                    listBrandColors.push(color);
                }
            });
        }

        // console.log(' -- Video brand ', this.videoManger.videoBrand);
        // console.log(' -- secondary list  ', this.videoManger.videoBrand.secondary_colors);
        // console.log(' -- listBrandColors  ', listBrandColors);
        let strDD = '';
        if (listBrandColors && listBrandColors.length > 0) {
            strDD += '<div class="headingm">Brand Colors</div><div>';
        }
        // $("#clr-picker").prependTo("#content")
        setTimeout(() => {
            listBrandColors.map((color, index) => {
                strDD +=
                    '<button id="clr-swatch-' +
                    index +
                    '" aria-labelledby="clr-swatch-label clr-swatch-' +
                    index +
                    '" style="color: ' +
                    color +
                    ';">' +
                    color +
                    '</button>';
            });
            if (listBrandColors && listBrandColors.length > 0) {
                strDD += '</div><div class="headingm">Other Colors </div>';
            }
            let fieldClear = $('#clr-swatches').find('div').eq(0).find('button').eq(0);
            fieldClear.addClass('color-clear-icon');
            // fieldClear.style("z-index: 3; color: #ffffff; position:relative");
            fieldClear.html(
                '<div class="cancel-line" style="height: 1px; width: 21px; background-color: red; display: block; position: absolute; opacity: 1; z-index: 2;top: 8px; transform: rotate(127deg); padding: 1px !important;"></div>',
            );

            // let strClearIcon = `<button id="clr-swatch-6767" class='color-clear-icon'
            //     aria-labelledby="clr-swatch-label clr-swatch-clear" style="z-index: 3; color: #ffffff; position:relative">
            //     <div class="cancel-line" style="height: 1px; width: 21px; background-color: red; display: block;
            //     position: absolute; opacity: 1; z-index: 2;top: 8px; transform: rotate(127deg);
            //     padding: 1px !important;"></div></button>'`;
            // $('#clr-swatches').find('div').eq(0).prepend(strClearIcon);
            $('#clr-swatches').prepend($(strDD));
            // $('#clr-swatches').prepend($(strDD));
            $('.color-clear-icon').on('click', function (e) {
                // console.log(e.target);
                // console.log('clear color icon called..');
                // clearSelectedColor();

                window['clearSelectedColor'].zone.run(() => {
                    window['clearSelectedColor'].loadAngularFunction();
                });
            });
        }, 1000);

        // $(strDD).prepend('#clr-swatches');
        // document.getElementById("#clr-picker").prepend
    }

    canSetColor = true;

    clearSelectedColor() {
        // console.log('Clear every thing ..');
        this.canSetColor = false;
    }
    checkingCanCallAngularfn() {
        console.log('Called angular fn ');
    }

    clearUploadFields() {
        this.sUploadedLogoName = '';
    }
    openFileUploadModal() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '100wv';
        dialogConfig.height = '100vh';
        dialogConfig.panelClass = 'pronto-dialog';
        dialogConfig.closeOnNavigation = true;

        dialogConfig.data = {
            uploadFor: 'image_video_logo',
            multipleUpload: false,
            // accept: "video/mp4, video/avi",
            dropBoxText: '',
            heading: 'Upload a Logo',
            allowDuplicate: true,
            mediaLibraryId:
                this.videoManger.selectedVideo?.brand_id &&
                this.videoManger.videoBrand?.media_libs &&
                this.videoManger.videoBrand?.media_libs.length > 0
                    ? this.videoManger.videoBrand?.media_libs[0]._id?.$oid
                    : undefined,
        };

        let showUploadModal = true;
        if (!dialogConfig.data.mediaLibraryId) {
            delete dialogConfig.data.mediaLibraryId;
        }
        if (showUploadModal) {
            let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);

            dialogModal.afterClosed().subscribe((data: any) => {
                if (data?.status == 'success' || data?.type == 'success') {
                    const res = data.result[0];
                    this.oUploadedLogo = res;
                    this.videoManger.selectedVideo.logo_img_url = this.oUploadedLogo?.url;
                    this.sUploadedLogoName = res.name;
                    this.showLogoUploadModal = false;
                } else {
                }
            });
        } else {
            this.errorService.handleError('Brand does not have any Media Library', 'Logo Update', true, false, true);
        }
        this.mediaManger.replaceMediaFromVisualPopup.pipe(takeUntil(this.destroy$)).subscribe((item) => {
            if (item && item.length > 0) {
                this.oUploadedLogo = item[0];
                this.videoManger.selectedVideo.logo_img_url = this.oUploadedLogo?.url;
                this.sUploadedLogoName = this.oUploadedLogo.fpath;
                this.showLogoUploadModal = false;
            }
            this.mediaManger.showMediaReplacementPopup = false;
            this.cd.detectChanges();
        });
    }

    openSelectBrandLogo() {
        this.showBrandLogoSelectionPopup = true;
    }
    closeSelectBrandLogo() {
        this.showBrandLogoSelectionPopup = false;
    }

    brandLogoFile = undefined;
    onSelectBrandLogo($event) {
        this.brandLogoFile = $event;
        this.closeSelectBrandLogo();
        if ($event && $event.fpath) {
            this.brandUploadedSecondLogo = $event;
            this.videoManger.selectedVideo.logo_img = this.brandUploadedSecondLogo.fpath;
            this.videoManger.selectedVideo.logo_img_url = this.brandUploadedSecondLogo.url;
        }
    }
    showBrandSelection() {
        // this.showBrandLogoSelectionPopup = true;
        this.bDisplayUploadLogoSection = true;
    }

    displayOwnUploadedLogoSection = false;
    displayPRofileLogoSection = false;
    displayBrandUploadedLogoSection = false;
    uploadedLogoPreviewObj = undefined;
}
